import React from "react";
import Link from "next/link";
import getLinkProps from "./getLinkProps";
import {useRouter} from "next/router";
import {getLangFromRouter} from "../../helpers/setLang";

// General purpose link wrapper
function withNextLink(Component) {
    return React.forwardRef((props, ref) => {
        let {
            href,
            external,
            forceLink = false,
            as,
            passHref,
            prefetch,
            replace,
            scroll,
            shallow,
            ...restProps
        } = props;

        const nextLinkProps = { href, as, prefetch, replace, scroll, shallow };

        if (external === undefined) {
            // if not given, default is based on href
            if (
                typeof href === "string" &&
                (href.startsWith("http://") || href.startsWith("https://"))
            ) {
                external = true;
            } else {
                external = false;
            }
        }

        if ((href || forceLink) && !external) {
            return (
                <Link {...nextLinkProps} passHref prefetch={false}>
                    <Component {...restProps} href={href} ref={ref} />
                </Link>
            );
        }

        return <Component {...restProps} ref={ref} href={href} />;
    });
}

function withMagdaButrymNextLink(Component) {
    const NextComponent = withNextLink(Component);

    return React.forwardRef((props, ref) => {
        const { href, as, ...restProps } = props;
        const router = useRouter();

        const linkProps = getLinkProps(as, href,  getLangFromRouter(router));

        return <NextComponent {...restProps} ref={ref} {...linkProps} />
    });
}

export default withMagdaButrymNextLink;
