export default function getLinkProps(as, href, lang) {
    if (!href) {
        return {}
    }

    if (href.startsWith('http')) {
        return {
            as, href
        }
    }

    // automatically detect dynamic routes (collection, product etc).
    let newHref;

    if (!as && href.startsWith('/product/')) {
        newHref = "/product/[handle]";
    }
    else if (!as && href.startsWith('/collection/')) {
        newHref = "/collection/[handle]";
    }
    else if (!as && href.startsWith('/story/')) {
        newHref = "/story/[handle]";
    }
    else if (!as && href.startsWith('/category/')) {
        newHref = "/category/[handle]";
    }
    else {
        newHref = href;
    }

    // languages

    let newAs = `/${lang}${href}`;
    newHref = `/[lang]${newHref}`;

    return {
        as: newAs,
        href: newHref,
        // prefetch: false
    };
}
